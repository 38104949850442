var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"container"},[_c('el-form',{ref:"form",staticClass:"search-form",attrs:{"model":_vm.form,"inline":true,"label-width":"0px"}},[_c('el-form-item',{attrs:{"label":"","size":"small"}},[_c('el-select',{staticStyle:{"width":"160px"},attrs:{"clearable":"","placeholder":"链接类型"},model:{value:(_vm.form.token_type),callback:function ($$v) {_vm.$set(_vm.form, "token_type", $$v)},expression:"form.token_type"}},[_c('el-option',{attrs:{"label":"组件","value":1}}),_c('el-option',{attrs:{"label":"漏洞","value":2}})],1)],1),_c('el-form-item',{attrs:{"label":"","size":"small"}},[_c('el-select',{staticStyle:{"width":"160px"},attrs:{"clearable":"","placeholder":"链接状态"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.status),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":""}},[_c('el-date-picker',{attrs:{"clearable":"","size":"small","type":"daterange","range-separator":_vm.separator,"start-placeholder":"操作时间","default-time":['00:00:00', '23:59:59'],"end-placeholder":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1),_c('div',{staticClass:"table-content"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.showLoading),expression:"showLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData.data}},[_c('el-table-column',{key:Math.random(),attrs:{"prop":"title","min-width":"380px","label":"链接名称"}}),_c('el-table-column',{attrs:{"prop":"token_type","label":"链接类型","min-width":"98px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.token_type==2)?_c('div',[_c('i',{staticClass:"icon iconfont iconbug1",staticStyle:{"color":"#E56363","margin-right":"4px","vertical-align":"top"}}),_vm._v("漏洞")]):_c('div',[_c('i',{staticClass:"icon iconfont iconzujian",staticStyle:{"color":"#2F90EA","margin-right":"4px","vertical-align":"top"}}),_vm._v("组件")])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"链接状态","min-width":"120px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:({color: _vm.status[row.status] && _vm.status[row.status].color })},[_c('span',{staticClass:"dot",style:({background: _vm.status[row.status] && _vm.status[row.status].color})}),_vm._v(" "+_vm._s(_vm.status[row.status] && _vm.status[row.status].label))])]}}])}),_c('el-table-column',{attrs:{"prop":"time","min-width":"120px","label":"到期时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatterTime(row.expire_at, false))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"create_user_name","min-width":"120px","label":"操作人"}}),_c('el-table-column',{attrs:{"prop":"type","label":"生成时间","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatterTime(row.create_at, true))+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"140","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status==1 )?[_c('el-tooltip',{attrs:{"effect":"dark","content":"复制链接","placement":"top"}},[_c('el-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(row.full_url),expression:"row.full_url",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"icon iconfont iconlink g-icon-btn",attrs:{"type":"text","autofocusv-clipboard:error":"onError"}})],1),_c('span',{staticClass:"l"}),_c('el-tooltip',{attrs:{"effect":"dark","content":"禁用","placement":"top"}},[_c('el-popconfirm',{attrs:{"icon":"el-icon-warning","title":"确定要禁用当前漏洞分享记录？"},on:{"confirm":function($event){return _vm.handleStop(row)}}},[_c('el-button',{staticClass:"icon iconfont iconstop g-icon-btn",attrs:{"slot":"reference","type":"text"},slot:"reference"})],1)],1),_c('span',{staticClass:"l"}),_c('el-tooltip',{attrs:{"effect":"dark","content":"修改有效期","placement":"top"}},[_c('el-button',{staticClass:"icon iconfont iconedit g-icon-btn",attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(row)}}})],1)]:_c('el-tooltip',{attrs:{"effect":"dark","content":"删除","placement":"top"}},[_c('el-popconfirm',{attrs:{"icon":"el-icon-warning","title":"确定要删除当前漏洞分享记录？"},on:{"confirm":function($event){return _vm.handleDelete(row)}}},[_c('el-button',{staticClass:"icon iconfont icondelete1 g-icon-btn",attrs:{"slot":"reference","type":"text"},slot:"reference"})],1)],1)]}}])})],1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.tableData.currPage,"layout":"total, prev, pager, next, jumper","total":_vm.tableData.total},on:{"current-change":_vm.handleCurrentChange}})],1)],1),_c('ShareDialog',{attrs:{"show":_vm.showShareDialog,"id":_vm.currId},on:{"update:show":function($event){_vm.showShareDialog=$event},"list":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }