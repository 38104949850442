

































































































































import VueBase from '@/VueBase'
import { Component, Watch } from 'vue-property-decorator'
import moment from 'moment'
import ShareDialog from '@/components/share/shareDialog.vue'

@Component({ name: 'VulnSharing', components: { ShareDialog } })
export default class DepartmentList extends VueBase {
	private showLoading: boolean = false
	private tableData: any = {
		data: [{
			name: 'name'
		}],
		total: 0,
		currPage: 1
	}
	private status: any =  [
		{
			label: '禁用',
			color: '#E56363',
			value: 0
		},
		{
			label: '正常',
			color: '#059669',
			value: 1
		},
		{
			label: '失效',
			color: '#6B7280',
			value: 2
		},
	]
	private form: any = {
		create_at_after: '',
		create_at_before: '',
		status: '',
		token_type: ''
	}
	private separator: string = ''
	private time: any = []
	created() {
		this.getList()
	}
	@Watch('time', {deep: true})
	onTimeChanged(newVal: any){
		console.log('---', newVal, newVal?.length < 1)
		if (!newVal || newVal?.length < 1) {
			this.separator = ''
			this.form.create_at_before = ''
			this.form.create_at_after = ''
			return
		}
		this.separator = '至'
		this.form.create_at_before = newVal[1]
		this.form.create_at_after = newVal[0]
	}
	@Watch('form', {deep: true})
	onFormChanged(newVal: any){
		this.getList()
	}

	async getList() {
		this.showLoading = true
		const params = {
			page_size: 10,
			page: this.tableData.currPage,
			...this.form
		}

		for (const key in params) {
			params[key] !== '' ? null : delete params[key] 
		}
		let { data, msg, status, page} = await this.services.share.shareList(params)
		this.showLoading = false
		if (status !== 201) {
			this.$message.error(msg)
			return
		}
		this.tableData.data = data
		this.tableData.total = page.alltotal
	}
	async handleStop(row: any) {
    this.showLoading = true
		let res = await this.services.share.shareEdit(row.id, {
			title: row.title,
			expire_at: row.time,
			status: 0
		})
		this.showLoading = false
		this.getList()
		if(res.status == 201) {
			this.$message.success('链接禁用成功')
			return
		}
		this.$message.error('链接禁用失败，请重试')
		
	}
	async handleDelete(row: any) {
      this.showLoading = true
			let res = await this.services.share.shareDelete(row.id)
			this.showLoading = false
			this.getList()
			if(res.status == 201) {
				this.$message.success('链接删除成功')
				return
			}
			this.$message.error('链接删除失败，请重试')
	}
	onCopy() {
		this.$message.success('链接复制成功');
	}
	onError() {
		this.$message.error('链接复制失败，请重试');
	}

	private showShareDialog: boolean = false
	private currId = ''
	handleEdit(row: any) {
		this.currId = row.id
		this.showShareDialog = true
	}
	
	formatterTime(time: any, isHMS: boolean) {
		let format = isHMS ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
		return moment(new Date(time)).format(format)
	}
	handleCurrentChange(val: any) {
		this.tableData.currPage = val
		this.getList()
		console.log(`当前页: ${val}`);
	}
	getColor(row: any) {
		return (this.status[row.status] as any).color
	}
}
